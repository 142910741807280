import { createEnv } from "@t3-oss/env-nextjs";
import { vercel } from "@t3-oss/env-nextjs/presets";
import { z } from "zod";
import * as process from "process";

export const env = createEnv({
  /*
   * Serverside Environment variables, not available on the client.
   * Will throw if you access these variables on the client.
   */
  server: {
    NODE_ENV: z.enum(["development", "test", "production"]),
    MLH_CORE_CLIENT_ID: z.string(),
    MLH_CORE_CLIENT_SECRET: z.string(),
    MLH_CORE_SCOPES: z.string(),
    MLH_CORE_BASEURL: z.string(),
    TURNSTILE_SECRET_KEY: z.string(),
    KV_REST_API_URL: z.string(),
    KV_REST_API_TOKEN: z.string(),
    EDGE_CONFIG: z.string(),
    EDGE_CONFIG_HYPERTUNE_ITEM_KEY: z.string(),
    HYPERTUNE_ADMIN_TOKEN: z.string(),
    FLAGS_SECRET: z.string(),
    CF_ACCESS_CLIENT_ID: z.string().optional(),
    CF_ACCESS_CLIENT_SECRET: z.string().optional(),
    SECONDARY_DOMAIN: z.string().optional(),
    BYPASS_TURNSTILE_SECRET_KEY: z
      .string()
      .default(
        (
          (Math.random() * 1e3 * Math.random() * 1e3) ** Math.random() *
          1e3
        ).toString(36)
      ),
    VALID_REFERRER_DOMAINS: z.preprocess(
      (v: string) => v.split(","),
      z.array(z.string()).default(["mlh.io", "mlh.com"])
    ),
  },
  /*
   * Environment variables available on the client (and server).
   *
   * 💡 You'll get type errors if these are not prefixed with NEXT_PUBLIC_.
   */
  client: {
    NEXT_PUBLIC_NODE_ENV: z.enum(["development", "production", "test"]),
    NEXT_PUBLIC_TURNSTILE_SITE_KEY: z.string(),
    NEXT_PUBLIC_URL: z.string(),
    NEXT_PUBLIC_SEGMENT_KEY: z.string().optional(),
    NEXT_PUBLIC_HYPERTUNE_TOKEN: z.string(),
  },
  shared: {
    NODE_ENV: z.enum(["development", "production", "test"]),
    NEXT_PUBLIC_SEGMENT_KEY: z.string().optional(),
    NEXT_PUBLIC_HYPERTUNE_TOKEN: z.string(),
  },
  /*
   * Destructure client variables only.
   */
  experimental__runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_NODE_ENV: process.env.NEXT_PUBLIC_NODE_ENV,
    NEXT_PUBLIC_TURNSTILE_SITE_KEY: process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY,
    NEXT_PUBLIC_URL:
      process.env.NEXT_PUBLIC_URL ||
      `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`,
    NEXT_PUBLIC_SEGMENT_KEY: process.env.NEXT_PUBLIC_SEGMENT_KEY,
    NEXT_PUBLIC_HYPERTUNE_TOKEN: process.env.NEXT_PUBLIC_HYPERTUNE_TOKEN,
  },
  emptyStringAsUndefined: true,
  extends: [vercel],
  isServer: typeof window === "undefined" || typeof EdgeRuntime === "string",
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
});
